<template lang='pug'>
  v-card
    v-snackbar(v-model='show' color='red' right top :timeout="4000")
      | {{message}}
      v-btn(dark='' text='' @click='show = false')
        | Close
    v-card-title
      | Banking information
      v-spacer
        v-icon(aria-label="Close" @click="$emit('close')")
          | mdi-close
    v-card-text.body-1.text-center
      v-row
        v-form
        v-container(pa-0='')
            v-layout(wrap='')
            v-flex(xs12='', md12='')
                v-layout.column
                    v-form(ref="form" v-model="valid")
                        v-row
                            v-col(cols="12" md="12")
                                v-text-field(v-model='model.first_name' type='text' label='First Name' required)
                            v-col(cols="12" md="12")
                                v-text-field(v-model='model.last_name' type='text' label='Last Name' required)
                            v-col(cols="12" md="12")
                                v-text-field(v-model='model.email' type='email' label='Email' :rules="rules.emailRules" required)
                            v-col(cols="12" md="12")
                                v-text-field(v-model='model.account' type='text' label='Bank account' required)
                            v-col(cols="12" md="12")
                                v-text-field(v-model='model.routing' type='number' label='Routing number' :rules="rules.routingRules" required)
                            v-col(cols="12" md="12")
                                v-text-field(v-model='model.site' type='text' label='Web site')
                v-btn( medium dark  @click='cancel') Cancel
                v-btn(color='primary', @click='save' :loading="isLoading" :disabled = '!model.first_name || !model.last_name || !model.email || !model.account' )
                    | Save
</template>
<script>
  import axios from 'axios'
  import * as vars from '@/vars.json'
  export default {
    props: {
      banking: Object,
    },
    data () {
      return {
        valid: true,
        model: {},
        isLoading: false,
        message: '',
        show: false,
        color: 'red',
      }
    },
    computed: {
      rules () {
        return {
          emailRules: [
            v => !!v || 'Required',
            v => /.+@.+\..+/.test(v) || 'Email format',
          ],
          routingRules: [
            v => !!v || 'Required',
            v => (v && v.length >= 9) || 'min length: 9 numbers',
            v => (v && v.length <= 9) || 'max length: 9 numbers',
          ],
        }
      },
    },
    mounted () {
      const vm = this
      vm.model = { ...vm.banking }
    },
    methods: {
      reset () {
        const vm = this
        vm.model = {}
      },
      cancel () {
        this.reset()
        this.$emit('close')
      },
      save () {
        const vm = this
        // if (this.$refs.form.validate()) {
        vm.isLoading = true
        axios.post(vars.urlProd + '/createAccount', vm.model).then(res => {
          const banking = {
            first_name: vm.model.first_name,
            last_name: vm.model.last_name,
            email: vm.model.email,
            account: res.data.id,
          }
          this.$store.dispatch('withdraw/updateBanking', banking).then(() => {
            vm.isLoading = false
            vm.$emit('close')
          })
        }).catch(e => {
          vm.isLoading = false
          // console.log(e.response.data.raw.message)
          vm.message = e.response.data.raw.message
          vm.show = true
        })
        // } else {
        // vm.message = 'Invalid format email'
        // vm.show = true
        // }
      },
    },
  }
</script>
